<template>
  <div class="input-faq">
    <draggable v-model="faq" item-key="id" class="faq-list" handle=".handle" ghost-class="ghost">
      <div v-for="(item, index) in faq" :key="`faq-${index}`" class="faq">
        <div class="handle unselect">
          <i class="material-icons">drag_indicator</i>
        </div>
        <div class="faq-item">
          <div class="faq-item-question">
            <div class="faq-title">Q</div>
            <textarea v-model="item.question" placeholder="질문" />
            <img
              src="/static/icon/u_trash-alt.svg"
              class="svg-sub4 trash unselect"
              @click="removeFaq(index)"
            />
          </div>
          <div class="faq-item-answer">
            <div class="faq-title">A</div>
            <textarea v-model="item.answer" placeholder="답변" />
          </div>
        </div>
      </div>
    </draggable>
    <div class="btn-add-wrap" :style="faq.length > 0 ? {paddingLeft: '32px'} : {}">
      <button class="button" @click="addFaq">
        <i class="material-icons">add</i>
        질문추가
      </button>
      <button class="button" @click="clickOpen">
        <img src="/static/icon/u_file-import.svg" alt="">
        다른 템플릿 FAQ 불러오기
      </button>
    </div>
    <sweet-modal ref="modal" overlay-theme="dark" :enableMobileFullscreen="false">
      <div class="modal-title">다른 템플릿 FAQ 불러오기</div>
      <div class="modal-content">
        <div class="card unselect" v-for="item in products" :key="`product-${item.id}`"
             @click="selectProduct(item.id)">
          <div class="radio" :class="{'radio-checked': selected === item.id}"></div>
          <img :src="item.img" :alt="item.name" :style="imgStyle">
          <div class="card-content">
            <div class="flex-align" style="gap: 4px">
              <div class="tag" :class="`tag-${item.request_status}`">{{ item.request_status_kor }}</div>
              <div class="category">{{ item.template_categories }}</div>
            </div>
            <div class="card-title">{{ item.name }}</div>
            <div class="card-desc">FAQ : {{ item.faq.length }}개</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="button is-primary" @click="clickImport">추가</button>
      </div>
    </sweet-modal>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import { SweetModal } from 'sweet-modal-vue'
import UserAPIMixin from '../../mixins/UserAPIMixin';
export default {
  name: "InputFaq",
  mixins: [UserAPIMixin],
  components: {
    draggable,
    SweetModal
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.getProducts();
  },
  data() {
    return {
      faq: this.value,
      selected: null,
      products: []
    };
  },
  computed: {
    imgStyle() {
      const [width, height] = this.imgRate(60);
      return {
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: '8px',
        marginLeft: '4px',
        objectFit: 'contain'
      }
    }
  },
  methods: {
    addFaq() {
      this.faq.push({ question: "", answer: "" });
    },
    removeFaq(index) {
      this.faq.splice(index, 1);
    },
    selectProduct(id) {
      this.selected = id;
    },
    getProducts() {
      this.request.user.get('product/my_faq').then(res => {
        this.products = res.data;
      });
    },
    clickOpen() {
      this.selected = null;
      this.$refs.modal.open();
    },
    clickImport() {
      if(this.selected) {
        this.faq = this.faq.concat(this.cloneItem(this.products.find(item => item.id === this.selected).faq));
        this.$refs.modal.close();
      } else {
        this.toast('불러올 템플릿을 선택해주세요.');
      }
    }
  },
  watch: {
    faq(val) {
      this.$emit("update:value", val);
    },
    value() {
      this.faq = this.value;
    }
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.input-faq
  .faq
    display flex
    gap 10px

  .handle
    cursor grab
    font-size 24px
    color $sub3
  .ghost
    opacity 0.5
  .faq-item
    flex 1
    border 1px solid $gray1
    border-radius 8px
    overflow hidden
    margin-bottom 16px
    font-size 15px
    .faq-item-question
      padding 12px 16px
      display flex
      gap 10px
      .faq-title
        color $main
        font-weight 700
      textarea
        font-size 15px
        flex 1
        border none
        outline none
        resize none
    .faq-item-answer
      border-top 1px solid $gray1
      padding 12px 16px
      background-color $gray4
      display flex
      gap 10px
      .faq-title
        color $primary
        font-weight 700
      textarea
        font-size 15px
        background-color transparent
        flex 1
        border none
        outline none
        height 76px
        resize none

  .btn-add-wrap
    display flex
    gap 10px

  .button
    width 100%
    height 48px
    font-size 16px
    font-weight 500
    display flex
    align-items center
    justify-content center
    gap 4px
    i
      font-size 18px

  .trash
    width 24px
    height 24px


.modal-title
  font-size 22px
  font-weight 700
  border-bottom 1px solid $gray1
  text-align left 
  padding 24px


.modal-content
  flex 1
  overflow-y auto
  padding 24px
  text-align left
  display flex
  flex-direction column
  gap 24px
  max-height calc(100vh - 300px)
.modal-footer
  padding 24px
  border-top 1px solid $gray1
  button
    width 100%
    height 52px
    line-height 52px
    font-size 16px
    font-weight 700

.card
  display flex
  align-items center
  gap 8px
  img
    border 1px solid $gray
  .radio
    width 24px
    height 24px 
    border 1px solid $gray
    border-radius 50%
  .radio-checked
    border 2px solid $primary
    display flex
    align-items center
    justify-content center
    &::after
      content ''
      width 12px
      height 12px
      background-color $primary
      border-radius 50%
  .tag
    padding 2px 6px
    border-radius 4px
    font-size 12px
    font-weight 700
    &.tag-1
    &.tag-4
      background-color #0063F7
      color white
    &.tag-9
      background-color $gray2
      color $main
    &.tag-0
      background-color $main
      color white
  .category
    color $sub3
    font-size 13px
    text-overflow ellipsis
    overflow hidden
    white-space nowrap
    flex 1
  .card-title
    font-size 14px
    font-weight 700
    color $main
    margin-top 2px
    text-overflow ellipsis
    overflow hidden
    white-space nowrap
  .card-content
    width calc(100% - 104px)
  .card-desc
    color $sub2
    font-size 14px

@media (max-width: 768px)
  .btn-add-wrap
    flex-direction column
  .modal-content
    max-height calc(100vh - 170px)
</style>
<style lang="stylus">
@media (min-width: 769px)
  .input-faq
    .sweet-modal
      max-height 90vh
      overflow hidden
      border-radius 12px !important
    .sweet-modal .sweet-box-actions
      top 20px
      right 16px
    .sweet-modal.is-alert .sweet-content
      padding 0
      overflow hidden
      padding-bottom 0 !important

@media (max-width: 768px)
  .input-faq
    .sweet-modal
      width 100%
      height 100vh
      overflow hidden
      border-radius 0 !important
    .sweet-modal .sweet-box-actions
      top 20px
      right 16px
    .sweet-modal.is-alert .sweet-content
      padding 0
      overflow hidden
      padding-bottom 0 !important
</style>

